<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push(`/tabs/clubs/${clubId}/trainers`)"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.send-message')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="3">
      <ion-item>
        <ion-label position="floating">{{ __('interface.message') }}</ion-label>
        <ion-textarea v-model="message"/>
      </ion-item>
      <ion-button expand="full" class="ion-margin-vertical" @click="actionSend">
        {{ __('interface.send-message') }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {alertController, IonPage, IonTextarea, loadingController} from "@ionic/vue";
import {API} from "@/utils/apiWrapper";

export default {
  name: "TrainerMessageModal",
  components: {IonTextarea, IonPage},
  data () {
    return {
      message: ''
    }
  },
  computed: {
    trainerId() {
      return this.$route.params.trainerId
    },
    clubId() {
      return this.$route.params.clubId
    }
  },
  methods: {
    async actionSend() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.post('TheSpottApi', '/send-message-to-trainer', {
        body: {
          trainerId: this.trainerId,
          message: this.message
        }
      }).then(async () => {
        this.$tracking.event({
          eventName: 'message-trainer',
          category: 'clubs'
        });
        const alert = await alertController.create({
          header: this.__('interface.success'),
          message: this.__('message.message-sent-successfully'),
          buttons: [{
            text: this.__('message.ok'),
            handler: () => {
              this.$router.push(`/tabs/clubs/${this.clubId}/trainers`)
            }
          }],
        });
        return alert.present();
      }).catch(async () => {
        const alert = await alertController.create({
          header: this.__('interface.error'),
          message: this.__('message.error-occurred-while-sending-message'),
          buttons: [this.__('message.ok')],
        });
        return alert.present();
      }).finally(() => {
        loading.dismiss();
      })
    }
  }
}
</script>